<template>
  <div class="main" :style="{ height: `100vh` }" @touchmove.prevent @mousewheel.prevent>
    <div :class="start ? '' : 'count-down'"></div>
    <!-- 返回按钮 -->
    <div class="go-back" @click="close" :style="{ top: `${12 + statusHeight}px` }">
      <van-icon name="arrow-left" color="#fff" />
    </div>

    <div class="num" :style="{ top: `${5 + statusHeight}px` }">
      <!-- 未开始 -->
      <div class="num-inner" v-if="!start && canJoin">
        <div>倒计时</div>
        <!-- 3 -->
        <div class="time-three" v-if="this.timeBeforeMin == 0 && timeBeforeSec == 3"></div>
        <!-- 2 -->
        <div class="time-two" v-else-if="this.timeBeforeMin == 0 && timeBeforeSec == 2"></div>
        <!-- 1 -->
        <div class="time-one" v-else-if="this.timeBeforeMin == 0 && timeBeforeSec == 1"></div>
        <!-- 倒计时 -->
        <div class="time" v-else>
          <span style="margin-right: 10px">剩余</span>
          <span>{{ timeBeforeMin }}</span>
          <span>:</span>
          <span>{{ timeBeforeSec }}</span>
        </div>
      </div>

      <!-- 参加过了 -->
      <div class="num-inner" v-if="!start && !canJoin" style="margin-top: 34px; font-size: 18px">
        您已经参加过了
      </div>

      <!-- 已开始 -->
      <div class="num-inner" v-if="start">
        <div style="font-size: 14px">
          <span style="margin-right: 10px">剩余时间</span>
          <span>{{ timeAfterMin }}</span>
          <span>:</span>
          <span>{{ timeAfterSec }}</span>
        </div>
        <div class="nums">
          <div class="background-num">{{ packageNum1 }}</div>
          <div class="background-num">{{ packageNum2 }}</div>
          <div class="background-num">{{ packageNum3 }}</div>
          <div style="font-size: 14px">个</div>
        </div>
        <div style="font-size: 14px">已获得红包</div>
        <div class="gold-num-add">
          <span v-if="goldOne">+{{ typeJiangli == '1' ? goldOne : goldOne.toFixed(2)   }}{{ typeJiangli == '1' ? '驿马' : '现金' }}</span>
        </div>
      </div>
    </div>

    <!-- 下落的红包 -->
    <div id="red_packet">
      <div v-for="(item, index) in packages" :key="index" class="red-package" :style="{
        top: `${200 + statusHeight}px`,
        left: `${item.left}%`,
        width: `${item.width}px`,
        height: `${item.height}px`,
        backgroundImage: `url(${item.picUrl})`,
        animationDuration: `${600 / speed}s`,
      }" @webkitAnimationEnd="deleteSelf" @touchstart.once="packageClick($event, item)"></div>
    </div>

    <!-- 结束领取 -->
    <van-overlay :show="finish">
      <div class="pic-box">
        <div class="finish-pic">
          <div class="gold-num">
            <span style="font-size: 25px">{{ typeJiangli == '1' ? packageGold : packageGold.toFixed(2) }}</span>
            <span style="font-size: 24px">{{ typeJiangli == '1' ? '驿马' : '现金'}}</span>
          </div>
          <div class="btn" @click="getGold"></div>
        </div>
        <div style="color: #fff; margin-top: 20px">领完红包，别忘记分享哦</div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getRain, getRainAward, getRainAward2 } from "@/api/redPackageRain";
export default {
  name: "redPackageRain",
  props: {},
  components: {},
  data() {
    return {
      id: null,
      canJoin: true, //是否可以参加
      start: false, //是否开始
      finish: false, //是否结束
      upperLimit: 1, //上限
      lowerLimit: 0, //下限
      duration: 1000, //持续时间

      numPerSec: 4, // 每秒生成几个红包
      speed: 400, //红包下落速度 每秒多少像素

      timeBefore: null, // 开始前 倒计时时间
      timeBeforeMin: "00", // 开始前 倒计时时间 分
      timeBeforeSec: "00", // 开始前 倒计时时间 秒
      timerBefore: null,

      timeAfter: null, // 开始后 倒计时时间
      timeAfterMin: "00", // 开始后 倒计时时间 分
      timeAfterSec: "00", // 开始后 倒计时时间 秒
      timerAfter: null,

      packageNum: 0, //点击获取的红包总数
      packageGold: 0, //点击获取的红包总数
      packages: [], //所有红包
      timerCreate: null,
      goldOne: 0,
      timerGold: true,

      statusHeight: 20, //状态栏高度

      // 所有红包样式
      packageStyles: [
        {
          width: 78 * 0.9,
          height: 91 * 0.9,
          picUrl: require("../../assets/img/redPacketRain/red1.png"),
          picUrlOpen: require("../../assets/img/redPacketRain/picUrlOpen.png")
        },
        {
          width: 79 * 0.9,
          height: 102 * 0.9,
          picUrl: require("../../assets/img/redPacketRain/red2.png"),
          picUrlOpen: require("../../assets/img/redPacketRain/picUrlOpen.png")
        },
        {
          width: 88 * 0.9,
          height: 73 * 0.9,
          picUrl: require("../../assets/img/redPacketRain/red3.png"),
          picUrlOpen: require("../../assets/img/redPacketRain/picUrlOpen.png")
        },
        {
          width: 76 * 0.9,
          height: 97 * 0.9,
          picUrl: require("../../assets/img/redPacketRain/red4.png"),
          picUrlOpen: require("../../assets/img/redPacketRain/picUrlOpen.png")
        },
        {
          width: 97 * 0.9,
          height: 103 * 0.9,
          picUrl: require("../../assets/img/redPacketRain/red5.png"),
          picUrlOpen: require("../../assets/img/redPacketRain/picUrlOpen.png")
        },
        {
          width: 83 * 0.9,
          height: 100 * 0.9,
          picUrl: require("../../assets/img/redPacketRain/red6.png"),
          picUrlOpen: require("../../assets/img/redPacketRain/picUrlOpen.png")
        },
      ],
      styleIndex: 0,
      site: 1,

      receiving: false,
      typeJiangli: ''
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    if (this.$route.query.session) {
      this.$store.commit("setSession", this.$route.query.session);
      console.log(this.$route.query.session);
    }
    this.getDetail();
  },
  mounted() {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    try {
      if (isIOS) {
        window.webkit.messageHandlers.setStatusBar.postMessage("1");
      }
      if (isAndroid) {
        window.android.setStatusBar("1");
      }
    } catch (error) {
      null;
    }

    window.statusBarHeight = this.statusBarHeight;
  },
  beforeDestroy() {
    clearInterval(this.timerBefore);
    clearInterval(this.timerAfter);
    clearInterval(this.timerCreate);

    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    try {
      if (isIOS) {
        window.webkit.messageHandlers.setStatusBar.postMessage("0");
      }
      if (isAndroid) {
        window.android.setStatusBar("0");
      }
    } catch (error) {
      null;
    }
  },
  computed: {
    // 红包数量百位
    packageNum1() {
      return Math.floor(this.packageNum / 100);
    },
    // 红包数量十位
    packageNum2() {
      return Math.floor((this.packageNum / 10) % 10);
    },
    // 红包数量个位
    packageNum3() {
      return this.packageNum % 10;
    },
  },
  watch: {},
  methods: {
    close() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
    // 获取详情
    async getDetail() {
      let res = await getRain(this.id);
      this.typeJiangli = res.data.data.type

      // 是否可以参加
      // this.canJoin = res.data.data.status == 1 ? false : true;
      if (res.data.data.status == 0) {
        this.canJoin = true;
      } else if (res.data.data.againFlag) {
        this.canJoin = true;
      } else {
        this.canJoin = false;
      }

      if (this.canJoin) {
        this.upperLimit = res.data.data.amountMax; //上限
        this.lowerLimit = res.data.data.amountMin; //下限
        this.duration = res.data.data.time * 1000; //持续时间

        var date = new Date().getTime();
        date += 3000;
        this.timeBefore = date;
        this.setTimeBefore();
        this.startBeforeInterval();
      }
    },
    // 每秒调用一次 开始前倒计时
    startBeforeInterval() {
      this.timerBefore = setInterval(() => {
        this.setTimeBefore();
      }, 1000);
    },
    // 计算开始前剩余时间
    setTimeBefore() {
      let now = new Date().getTime();
      let time = ((this.timeBefore - now) / 1000).toFixed(0);
      this.timeBeforeMin = Math.floor((time % 3600) / 60);
      this.timeBeforeSec = time % 60;
      this.timeBeforeMin = (this.timeBeforeMin + "").padStart(2, "0"); //补零
      this.timeBeforeSec = (this.timeBeforeSec + "").padStart(2, "0"); //补零
      if (this.timeBeforeMin <= 0 && this.timeBeforeSec <= 0) {
        this.activityStart();
      }
    },
    // 活动开始
    activityStart() {
      this.timeBefore = this.timeBeforeMin = this.timeBeforeSec = null;
      clearInterval(this.timerBefore);
      this.start = true;

      // 持续时间
      var date = new Date().getTime();
      date += this.duration; 
      this.timeAfter = date;

      // 倒计时
      this.setTimeAfter();
      this.startAfterInterval();

      // 循环 生成红包
      this.createPackage();
      this.startCreateInterval();
    },
    // 红包点击事件
    packageClick(e, item) {
      // 领取的红包数量+1
      this.packageNum++;
      // 根据金币上限和下限生成随机数
      let gold = parseFloat(
        (
          Math.random() * (this.upperLimit - this.lowerLimit) +
          this.lowerLimit
        ).toFixed(4)
      );
      item.gold = gold;
      // 总金币数
      this.packageGold = Number(
        ((gold * 10000 + this.packageGold * 10000) / 10000).toFixed(4)
      );

      // 点完删除
      // this.deleteSelf(e);
      let target = e.target;
      target.style.backgroundImage = `url(${item.picUrlOpen})`

      this.goldOne = gold;

      // 防抖
      // 每当用户输入的时候把前一个 setTimeout clear 掉
      clearTimeout(this.timerGold);
      // 然后又创建一个新的 setTimeout, 这样就能保证interval 间隔内如果时间持续触发，就不会执行 fn 函数
      this.timerGold = setTimeout(() => {
        this.goldOne = 0;
      }, 2000);

      // 点击处生成金币数量
      // var div = document.createElement("div");
      // div.innerHTML = "+" + gold + "币";
      // div.style.position = "absolute";
      // div.style.top = "200px";
      // // div.style.top = e.pageY + "px";
      // div.style.left = e.pageX + "px";
      // div.style.color = "red";
      // div.style.width = "80px";
      // div.style.height = "30px";
      // div.style.lineHeight = "30px";
      // div.style.marginLeft = "-20px";
      // div.style.marginTop = "-15px";
      // div.style.textAlign = "center";
      // div.style.fontSize = "24px";
      // document.querySelector(".main").appendChild(div);
      // // 显示后删除
      // setTimeout(() => {
      //   document.querySelector(".main").removeChild(div);
      // }, 300);
    },
    // 隔一段时间调用一次生成红包
    startCreateInterval() {
      this.timerCreate = setInterval(() => {
        this.createPackage();
      }, 1000 / this.numPerSec);
    },
    // 生成红包
    createPackage() {
      // 1: 10~30% (left)
      // 2: 30~50% (left)
      // 3: 50~70% (left)
      // 4: 70~90% (left)
      let sites = [1, 2, 3, 4].filter((x) => {
        return x != this.site;
      });
      this.site = sites[Math.floor(Math.random() * sites.length)];
      let left = Math.random().toFixed(2) * 20 + 20 * (this.site - 1) + 10;
      this.styleIndex = Math.floor(Math.random() * this.packageStyles.length);
      this.packages.push({
        gold: 0,
        left,
        ...this.packageStyles[this.styleIndex],
      });
    },
    // 删除红包dom
    deleteSelf(e) {
      let target = e.target;
      document.querySelector("#red_packet").removeChild(target);
    },
    // 每秒调用一次 开始后倒计时
    startAfterInterval() {
      this.timerAfter = setInterval(() => {
        this.setTimeAfter();
      }, 1000);
    },
    // 计算开始后剩余时间
    setTimeAfter() {
      let now = new Date().getTime();
      let time = ((this.timeAfter - now) / 1000).toFixed(0);
      this.timeAfterMin = Math.floor((time % 3600) / 60);
      this.timeAfterSec = time % 60;
      this.timeAfterMin = (this.timeAfterMin + "").padStart(2, "0"); //补零
      this.timeAfterSec = (this.timeAfterSec + "").padStart(2, "0"); //补零
      if (this.timeAfterMin <= 0 && this.timeAfterSec <= 0) {
        this.activityEnd();
      }
    },
    // 活动结束
    activityEnd() {
      clearInterval(this.timerAfter);
      clearInterval(this.timerCreate);
      this.packages = [];
      this.finish = true;
    },
    // 点击领取金币
    getGold() {
      if (!this.receiving) {
        this.receiving = true;
        this.$toast.loading({
          message: "领取中...",
          forbidClick: true,
          duration: 0,
        });
        if (this.typeJiangli == '1') {
          getRainAward({ redId: this.id, riceNum: this.packageGold }).then((res) => {
            if (res.data.code == 0) {
              this.$toast.clear();
              this.$toast("领取成功");

              setTimeout(() => {
                this.$router.replace(
                  `redPackageRainShare?num=${this.packageGold}&id=${this.id}&typeJiangli=${this.typeJiangli}`
                );
                this.receiving = false;
              }, 1000);
            }
          })
        } else {
          getRainAward2({ redId: this.id, money: this.packageGold.toFixed(2) }).then((res) => {
            if (res.data.code == 0) {
              this.$toast.clear();
              this.$toast("领取成功");

              setTimeout(() => {
                this.$router.replace(
                  `redPackageRainShare?num=${this.packageGold.toFixed(2)}&id=${this.id}&typeJiangli=${this.typeJiangli}`
                );
                this.receiving = false;
              }, 1000);
            }
          })
        }
      }
    },
    statusBarHeight(val) {
      this.statusHeight = +val;
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  position: relative;
  overflow: hidden;
  background-image: url("../../assets/img/redPacketRain/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #fff;

  .go-back {
    position: absolute;
    left: 16px;
    font-size: 22px;
  }

  .num {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    .bg(192px, 192px, "../../assets/img/redPacketRain/white circle.png");
    color: #fff;

    .num-inner {
      position: absolute;
      top: 50px;
      width: 192px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;

      .time {
        font-size: 22px;
        margin-top: 14px;
      }

      .time-three {
        margin-top: 8px;
        .bg(44px, 60px, "../../assets/img/redPacketRain/three.png");
      }

      .time-two {
        margin-top: 8px;
        .bg(42px, 59px, "../../assets/img/redPacketRain/two.png");
      }

      .time-one {
        margin-top: 8px;
        .bg(27px, 58px, "../../assets/img/redPacketRain/one.png");
      }

      .nums {
        display: flex;
        align-items: flex-end;
        margin: 8px 0;

        .background-num {
          .bg(28px, 32px, "../../assets/img/redPacketRain/num back.png");
          font-size: 22px;
          color: #fb4723;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 4px;
        }
      }

      .gold-num-add {
        .bg(188px, 49px, "../../assets/img/redPacketRain/gold-back.png");
        margin-top: 16px;
        font-size: 14px;
        font-family: PingFangHK-Medium, PingFangHK;
        font-weight: 500;
        color: #fff0ca;
        text-align: center;
        box-sizing: border-box;
        padding: 21px;
      }
    }
  }

  .red-package {
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% auto;
    background-position: center;
    position: absolute;
    transform: translate(-50%);
    animation-name: drop;
    animation-timing-function: linear;
  }

  .pic-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .finish-pic {
      position: relative;
      .bg(287px, 461px, "../../assets/img/redPacketRain/get.png");

      .gold-num {
        position: absolute;
        top: 180px;
        font-size: 45px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 1;
        background: linear-gradient(180deg, #ffc548 0%, #eb0000 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        width: 100%;
        text-align: center;
      }

      .btn {
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        width: 160px;
        height: 50px;
      }
    }
  }
}

.count-down {
  background-image: url("../../assets/img/redPacketRain/background count down.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #fef5ee;
  width: 100%;
  height: 100%;
}

.bg(@width, @height, @url) {
  width: @width;
  height: @height;
  background: url(@url);
  background-size: 100% 100%;
}

@keyframes drop {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    transform: translate(-50%, 600px);
    opacity: 0;
  }
}
</style>
